<template>
  <div class="col-12 col-lg-7 c">
    <div class="card">
      <div class="card-header">
        <h4 class="text-center">
          <i class="fa fa-user-edit"></i>
          تعديل الملف الشخصي
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <h5 for="">الإسم</h5>
          <input type="text" class="form-control" v-model="name" />
        </div>
        <div class="form-group">
          <h5 for="">
            كلمة المرور
            <a
              href="#"
              @click="
                viewPassword ? (viewPassword = false) : (viewPassword = true)
              "
              ><i class="fa fa-eye"></i> عرض</a
            >
          </h5>
          <input
            :type="viewPassword ? 'text' : 'password'"
            class="form-control"
            name=""
            id=""
            aria-describedby="helpId"
            placeholder=""
            v-model="password"
          />
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-relief-success" @click="add()">
            تعديل الملف الشخصي
            <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.name = this.user.name;
    this.password = this.user.password;
  },
  data() {
    return {
      viewPassword: false,
      user: JSON.parse(localStorage.getItem("user")),
      name: null,
      password: null,
    };
  },
  methods: {
    add() {
      var g = this;
      if (!g.password || !g.name) {
        alert("هناك حقول مطلوبة");
      } else {
        $.post(api + "/admin/auth/profile", {
          jwt: g.user.jwt,
          name: g.name,
          password: g.password,
        })
          .then(function (r) {
            r = JSON.parse(r);
            if (r.status == 100) {
              alert("تم تعديل الملف الشخصي بنجاح");
            } else {
              alert(r.response);
            }
          })
          .catch(function (r) {
            alert("حدث خطأ");
          });
      }
    },
  },
};
</script>

<style>
</style>